/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Athlete,
    AthleteFromJSON,
    AthleteToJSON,
} from '../models';

export interface ApiAthleteIdDeleteRequest {
    id: number;
}

export interface ApiAthleteIdGetRequest {
    id: string;
}

export interface ApiAthleteIdPutRequest {
    id: number;
    athlete?: Athlete;
}

export interface ApiAthletePostRequest {
    athlete?: Athlete;
}

/**
 * 
 */
export class AthleteApi extends runtime.BaseAPI {

    /**
     */
    async apiAthleteDownloadGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athlete/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiAthleteDownloadGet(initOverrides?: RequestInit): Promise<object> {
        const response = await this.apiAthleteDownloadGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAthleteGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Athlete>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athlete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AthleteFromJSON));
    }

    /**
     */
    async apiAthleteGet(initOverrides?: RequestInit): Promise<Array<Athlete>> {
        const response = await this.apiAthleteGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAthleteIdDeleteRaw(requestParameters: ApiAthleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAthleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athlete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAthleteIdDelete(requestParameters: ApiAthleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAthleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAthleteIdGetRaw(requestParameters: ApiAthleteIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Athlete>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAthleteIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athlete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AthleteFromJSON(jsonValue));
    }

    /**
     */
    async apiAthleteIdGet(requestParameters: ApiAthleteIdGetRequest, initOverrides?: RequestInit): Promise<Athlete> {
        const response = await this.apiAthleteIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAthleteIdPutRaw(requestParameters: ApiAthleteIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAthleteIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athlete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AthleteToJSON(requestParameters.athlete),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAthleteIdPut(requestParameters: ApiAthleteIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAthleteIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAthletePostRaw(requestParameters: ApiAthletePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Athlete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AthleteToJSON(requestParameters.athlete),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAthletePost(requestParameters: ApiAthletePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAthletePostRaw(requestParameters, initOverrides);
    }

}
