import { Select } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid'
import React from 'react'

export default function StatusFilterSelector(props: GridFilterInputValueProps) {

    const { item, applyValue } = props;

    // eslint-disable-next-line
    const handleFilterChange = (event: { target: { value: any; }; }) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <div id="sometest" style={{
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 48,
            paddingLeft: 20,
        }}>
            <Select
                native
                label={'Status'}
                value={item.value}
                defaultValue=""
                onChange={(e: any) => applyValue({ ...item, value: e.target.value })}
                inputProps={{ id: 'custom-months-filter' }}
            >
                <option value="" disabled>
                    Select
                </option>
                <option value="All">
                    All
                </option>
                <option value="Incomplete">
                    Incomplete
                </option>
                <option value="Missing">
                    Missing
                </option>
                <option value="Complete">
                    Complete
                </option>
            </Select>
        </div>
    )
}
