import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import React, { useContext, useState } from "react"

import { apiScopes, apiAuthority, backendBasePath } from "../config/config";

import { MsalContext } from '@azure/msal-react';
import { AdminApi, Athlete, AthleteApi, Configuration, RegistrationApi } from "../dal";
import { MainPageContext } from "./MainPageContext";

type AthleteDetailsContextType = {
    deleteRegistration: (registrationId: number) => Promise<boolean> | null;
    getAthlete: (athleteId: string) => void;
    selectedAthlete: Athlete | null;
    isDirty: number;
}

export const AthleteDetailsContext = React.createContext<AthleteDetailsContextType>({
    deleteRegistration: (r: number) => null,
    getAthlete: (athleteId: string) => null,
    selectedAthlete: null, 
    isDirty: 0

});

export function useAthleteDetailsContext() {


    const msalContext = useContext(MsalContext);

    const context = useContext(MainPageContext);

    const [selectedAthlete, setSelectedAthlete] = useState<Athlete | null>(null);

    const [isDirty, setIsDirty] = useState(0);


    const deleteRegistration = async (rid: number): Promise<boolean> => {

        try {
            let token = await silentRequest();

            let registrationApi = new RegistrationApi(token);

            await registrationApi.apiRegistrationIdDelete({ id: rid });

            if (selectedAthlete) {
                await getAthlete(selectedAthlete?.accreditationNumber   !);
                context.refreshAll();
            }
            else{
                setIsDirty(oldValue => oldValue+1);
            }

            return true;
        }
        catch (e: unknown) {
            console.log(e);
            return false;
        }

    }

    const getAthlete = (athleteId: string) => {

        if (context.currentUser!.roles!.filter(r => r.roleName = 'RoomingAdmin').length > 0) {
            silentRequest().then(c => {
                let adminApi = new AdminApi(c);
                adminApi.apiAdminAthleteIdPost({ id: athleteId.toString() }).then(a => {
                    setSelectedAthlete(a);
                }).catch(e => console.log(e));
            })
        }
        else {
            silentRequest().then(t => {
                let athleteApi = new AthleteApi(t);
                athleteApi.apiAthleteIdGet({ id: athleteId }).then(a => {
                    setSelectedAthlete(a);
                }).catch(e => { console.log(e) });
            })
        }
    }

    const silentRequest = () => {
        if (msalContext.accounts[0]) {
            const accessTokenRequest: SilentRequest = {
                scopes: apiScopes,
                account: msalContext.accounts[0] as AccountInfo,
                authority: apiAuthority,
            } as SilentRequest;

            return msalContext.instance
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    const accessToken = response.accessToken;
                    let c: Configuration = new Configuration({
                        accessToken: accessToken,
                        basePath: backendBasePath,
                    });
                    return c;
                });
        }
        return Promise.reject();
    };

    return {
        deleteRegistration,
        getAthlete,
        selectedAthlete,
        isDirty
    }
}