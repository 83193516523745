import { Building, Registration, RegistrationType } from "../dal"

export const GetRegistrationType = (regType: string): RegistrationType => {
    switch (regType) {
        case regType.indexOf('Athlete Village') > -1 ? regType : '':
            return RegistrationType.NUMBER_0
        case "hotel":
            return RegistrationType.NUMBER_1
        case "Hotel":
            return RegistrationType.NUMBER_1
        case "Private":
            return RegistrationType.NUMBER_2
        default:
            return RegistrationType.NUMBER_2
    }
}

export const GetRegistrationLabel = (regType: RegistrationType): string => {
    switch (regType) {
        case RegistrationType.NUMBER_0:
            return "Athlete Village"
        case RegistrationType.NUMBER_1:
            return "Hotel"
        case RegistrationType.NUMBER_1:
            return "Private"
        default:
            return "Private"
    }
}

export const GetRegistrationLabelEg = (reg: Registration, buildings: Building[]): string => {

    if (reg.registrationType === RegistrationType.NUMBER_0) {
        const buildingLocations = buildings.filter(b => b.buildingName === reg.buildingName);
        if (buildingLocations.length > 0) {
            return buildingLocations[0].buildingLocation ?? "Athlete Village - Krakow";
        }
    }
    else {
        return reg.registrationType === RegistrationType.NUMBER_1 ? "Hotel" : "Private"
    }
    return "Private";
}

export const GetRegistrationLabelFromShort = (s: string): string => {

    switch (s) {
        case 'Hotel':
            return 'Hotel'
        case 'Private':
            return 'Private'
        case 'Athlete Village':
            return 'Athlete Village'
        case 'Campus':
            return 'Athlete Village'
        default:
            return ''
    }

}