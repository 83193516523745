import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { AthleteDetailsContext, useAthleteDetailsContext } from '../../../context/AthleteDetailsContext';
import RegistrationRow from './RegistrationRow/RegistrationRow';
import { useParams } from "react-router-dom";

import AddRegistrationDialog from '../AddRegistrationDialog/AddRegistrationDialog';
import { MainPageContext } from '../../../context/MainPageContext';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';





export default function AthleteDetailsPane() {

    let navigate = useNavigate();
    const params = useParams();

    const appInsights = useAppInsightsContext();

    const handleBack = () => navigate("/");

    const [showNewRegistrationDialog, setShowNewRegistrationDialog] = useState(false);



    const athleteDetailsContext = useAthleteDetailsContext();


    const context = useContext(MainPageContext);

    useEffect(() => {

        appInsights.trackPageView({ name: 'Details' })
        
        athleteDetailsContext.getAthlete(params.athleteId ?? "123412");
        // eslint-disable-next-line
    }, [])

    const handleAddCompleted = () => {

        setTimeout(() => {
            athleteDetailsContext.getAthlete(athleteDetailsContext.selectedAthlete?.accreditationNumber!);
            context.refreshAll();
        }, 400);        
        
        setShowNewRegistrationDialog(false);
    }

    return (
        <AthleteDetailsContext.Provider value={athleteDetailsContext}>
            <div>
                <Box padding={"10px"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={handleBack}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <div style={{ marginLeft: "50px" }}>
                            <Typography variant="h4">
                                {athleteDetailsContext.selectedAthlete?.fullName}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ padding: "20px 20px 20px 20px" }}>
                        <TextField
                            label="Accreditation number:"
                            value={athleteDetailsContext.selectedAthlete?.accreditationNumber || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />

                        <br />
                        <br />

                        <TextField
                            label="Country:"
                            value={athleteDetailsContext.selectedAthlete?.country?.countryName || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />

                        <br />
                        <br />

                        <TextField
                            label="Sport:"
                            value={athleteDetailsContext.selectedAthlete?.discipline?.sportName || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px" }}>
                        {athleteDetailsContext.selectedAthlete?.registrations?.map((r, index) => {
                            return <RegistrationRow registration={r} key={`rr${index}`}></RegistrationRow>
                        })}
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px" }}>
                        <Button variant='contained' fullWidth onClick={() => setShowNewRegistrationDialog(true)}>Add registration</Button>
                    </div>
                </Box>
                <AddRegistrationDialog selectedRegistration={null} show={showNewRegistrationDialog} selectedAthletes={[athleteDetailsContext.selectedAthlete?.athleteId!]}  handleClose={() => handleAddCompleted()}></AddRegistrationDialog>
            </div>
        </AthleteDetailsContext.Provider>
    )
}
