import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React, { useContext, useState } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import PublicIcon from '@mui/icons-material/Public';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';

import styles from './AppSideMenuOptions.module.css'
import UploadDialog from '../UploadDialog/UploadDialog';
import { MainPageContext } from '../../../../context/MainPageContext';
import { useNavigate } from 'react-router-dom';


export default function AppSideMenuOptions() {


    const { downloadAthleteCsv, currentUser, refreshCache, downloadRegistrationsCsv } = useContext(MainPageContext);

    const [showUploadDialog, setShowUploadDialog] = useState(false);

    let navigate = useNavigate();

    const handleCountryListClick = () => {
        navigate('/countries')
    }

    const handleAthleteListClick = () => {
        navigate('/athletes')
    }

    const handleRegistrationListClick = () => {
        navigate("/");
    }

    return (
        <div className={styles.listContainer}>
            <div>
                <List>
                    {
                        currentUser?.roles?.filter(r => r.roleName === 'RoomingAdmin').length! > 0 &&
                        <ListItem key={"list"} onClick={() => { handleRegistrationListClick() }}>
                            <ListItemIcon color="#e3e3e3">
                                <FeaturedPlayListIcon color="info" />
                            </ListItemIcon>
                            <ListItemText primary={"Registration List"} />
                        </ListItem>
                    }
                    {
                        currentUser?.roles?.filter(r => r.roleName === 'RoomingAdmin').length! > 0 &&
                        <ListItem key={"countries"} onClick={() => handleCountryListClick()}>
                            <ListItemIcon color="#e3e3e3">
                                <PublicIcon color="info" />
                            </ListItemIcon>
                            <ListItemText primary={"Country List"} />
                        </ListItem>
                    }
                    {
                        currentUser?.roles?.filter(r => r.roleName === 'RoomingAdmin').length! > 0 &&
                        <ListItem key={"athletes"} onClick={() => handleAthleteListClick()}>
                            <ListItemIcon color="#e3e3e3">
                                <AccountBoxIcon color="info" />
                            </ListItemIcon>
                            <ListItemText primary={"Athlete List"} />
                        </ListItem>
                    }
                    {currentUser?.roles?.filter(r => r.roleName === 'RoomingStaff').length! === 0 &&
                        <ListItem onClick={() => setShowUploadDialog(true)} button key={"upload"}>
                            <ListItemIcon color="#e3e3e3">
                                <UploadFileIcon color="info" />
                            </ListItemIcon>
                            <ListItemText primary={"Upload registrations"} />
                        </ListItem>
                    }

                    {currentUser?.roles?.filter(r => r.roleName === 'RoomingStaff').length! === 0 &&
                        <ListItem key={"download_athletes"} onClick={() => { downloadAthleteCsv() }}>
                            <ListItemIcon color="#e3e3e3">
                                <FileDownloadIcon color="info" />
                            </ListItemIcon>
                            <ListItemText primary={"Download athletes"} />
                        </ListItem>
                    }

                    {currentUser?.roles?.filter(r => r.roleName === 'RoomingStaff').length! === 0 &&
                        <ListItem  key={"download_registrations"} onClick={() => { downloadRegistrationsCsv() }}>
                            <ListItemIcon color="#e3e3e3">
                                <FileDownloadIcon color="info" />
                            </ListItemIcon>
                            <ListItemText primary={"Download registrations"} />
                        </ListItem>
                    }

                    {
                        currentUser?.roles?.filter(r => r.roleName === 'RoomingAdmin').length! > 0 &&
                        <ListItem  key={"refresh"} onClick={() => { refreshCache() }}>
                            <ListItemIcon color="#e3e3e3">
                                <RefreshIcon color="info" />
                            </ListItemIcon>
                            <ListItemText primary={"Refresh cache"} />
                        </ListItem>
                    }
                </List>
            </div>
            <UploadDialog show={showUploadDialog} handleClose={() => setShowUploadDialog(false)}></UploadDialog>
        </div>
    )
}
