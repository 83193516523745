import { Dialog, DialogTitle, DialogContent, DialogContentText, Stack, DialogActions, Button, Paper, Typography, Alert, Link } from '@mui/material'
import React, { ChangeEvent, useContext } from 'react'
import { MainPageContext } from '../../../../context/MainPageContext';
import moment from 'moment';
import { saveAs } from "file-saver";
import { updateBuilding1 } from '../../../../resources/text/text';


interface UploadDialogProps {
    show: boolean;
    handleClose: () => void;
}


export default function UploadDialog(props: UploadDialogProps) {


    const { uploadAthleteRegistration, setHasUploadFinished, hasUploadFinished, uploadErrors, uploadSuccess, uploadErrorsCount, uploadSuccessCount, currentUser } = useContext(MainPageContext);

    const fileSelected = (event: ChangeEvent<HTMLInputElement>): void => {

        event.persist();

        if (event.target && event.target.files && event.target.files[0]) {
            console.log(event.target.files[0]);
            uploadAthleteRegistration(event.target.files[0]);
        }
    };

    const downloadFileError = () => {
        if (uploadErrors) {
            const blob: Blob = new Blob([uploadErrors!], { type: 'text/csv' });
            const fileName: string = currentUser?.country?.countryName + '_' + moment().format('YYYYMMDDhhmm') + '_errors.csv';
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);
        }
    }
    const downloadFileSuccess = () => {
        if (uploadErrors) {
            const blob: Blob = new Blob([uploadSuccess!], { type: 'text/csv' });
            const fileName: string = currentUser?.country?.countryName + '_' + moment().format('YYYYMMDDhhmm') + '_success.csv';
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);
        }
    }

    const downloadBuildingNames = () => { 
        saveAs("/files/BuildingNames.pdf", "BuildingNames.pdf");
    }

    return (
        <div>
            <Dialog open={props.show} onClose={props.handleClose}>
                <DialogTitle>Upload a new file</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>
                            <Alert severity="warning">
                                {updateBuilding1} <Link onClick={() => downloadBuildingNames()}>Download</Link>
                            </Alert>
                        </span>
                        <br />
                        <br />
                        <span>Please make sure to use the correct file format.</span>
                    </DialogContentText>
                    <Stack spacing={3} style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            Upload File
                            <input
                                type="file"
                                hidden
                                onChange={fileSelected}
                            />
                        </Button>

                        <div hidden={!hasUploadFinished}>
                            <Stack direction={"row"} spacing={3}>
                                <Paper style={{ padding: "10px 10px 10px 10px", width: "50%" }}>
                                    <div style={{ display: 'flex', justifyContent: "center", alignContent: 'center', alignItems: "center" }}>
                                        <div style={{ padding: "5px 5px 5px 5px" }}><Typography variant={'h3'}>{uploadSuccessCount}</Typography></div>
                                        <div style={{ padding: "5px 5px 5px 15px" }}>registration uploaded successfully</div>
                                    </div>
                                    <Button onClick={() => { downloadFileSuccess() }}>Download</Button>
                                </Paper>
                                <Paper style={{ padding: "10px 10px 10px 10px", width: "50%" }}>
                                    <div style={{ display: 'flex', justifyContent: "center", alignContent: 'center', alignItems: "center" }}>
                                        <div style={{ padding: "5px 5px 5px 5px" }}><Typography variant={'h3'}>{uploadErrorsCount}</Typography></div>
                                        <div style={{ padding: "5px 5px 5px 15px" }}>registrations failed during upload</div>
                                    </div>
                                    <Button onClick={() => { downloadFileError() }}>Download</Button>
                                </Paper>
                            </Stack>
                        </div>

                    </Stack>
                    <DialogActions>
                        <Button onClick={() => { setHasUploadFinished(false); props.handleClose() }}>Close</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}
